<template>
  <div class="border border-transparent divide-y divide-gray-200 rounded dark:border-gray-600">
    <div
      class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
    >
      Folders
    </div>
    <ul class="leading-6 divide-y divide-gray-200 select-none">
      <TreeItem
        v-for="asset in visibleAssets"
        :key="asset.id"
        :asset="asset"
        :assets="visibleAssets"
        :active-folder="activeFolder"
        :can-select="canSelect"
        :can-replace="canReplace"
        :can-delete="canDelete"
        :can-preview="canPreview"
        :can-rename="canRename"
        :dragger="dragger"
        :show-size="showSize"
        :show-options="showOptions"
        :show-type="showType"
        :show-comments="showComments"
        :show-modified="showModified"
        :restricted-action-types="restrictedActionTypes"
        :action-mode="actionMode"
        :is-selected="isSelectedAsset(asset)"
        :is-focused="isFocusedAsset(asset)"
        :is-multiselected="isMultiselectedAsset(asset)"
        @multiselect-asset="$emit('multiselect-asset', $event)"
        @multideselect-asset="$emit('multideselect-asset', $event)"
        @delete-asset="$emit('delete-asset', $event)"
        @preview-asset="$emit('preview-asset', $event)"
        @rename-asset="$emit('rename-asset', $event)"
        @replace-asset="$emit('replace-asset', $event)"
        @deselect-asset="$emit('deselect-asset', $event)"
        @drag-asset="$emit('drag-asset', $event)"
        @drag-asset-focus="$emit('drag-asset-focus', $event)"
        @drop-asset="$emit('drop-asset', $event)"
        @select-asset="$emit('select-asset', $event)"
        @view-asset="$emit('view-asset', $event)"
        @move-asset="$emit('move-asset', $event)"
      />
    </ul>

    <HiddenBlock
      v-if="hiddenItemCount > 0"
      :hidden-item-count="hiddenItemCount"
      :restricted-action-types="restrictedActionTypes"
    />
  </div>
</template>

<script>
import layout from './assets-explorer-collection-layout-mixin.js'
const TreeItem = () => import('./assets-explorer-collection-tree-item.vue')
const HiddenBlock = () => import('./assets-explorer-collection-hidden-block.vue')
const EmptyCollectionBlock = () => import('./assets-explorer-collection-empty-block.vue')

export default {
  name: 'AssetsExplorerCollectionTreeLayout',

  components: {
    TreeItem,
    EmptyCollectionBlock,
    HiddenBlock
  },

  mixins: [layout],

  computed: {

    sortDirection() {
      if (this.sortOrder === null || this.sortOrder === '') return ''
      let [, sortDirection] = this.sortOrder.split(':') // only get the 2nd argument from the destructured array
      return sortDirection.toLowerCase()
    },

    sortBy() {
      if (this.sortOrder === null || this.sortOrder === '') return ''
      let [sortBy] = this.sortOrder.split(':')// only get the 1st argument from the destructured array
      return sortBy.toLowerCase()
    }
  },

  methods: {
    sort(method) {
      let direction = 'asc'
      if (this.sortBy === method && this.sortDirection === 'asc') {
        direction = 'desc'
      }

      let sort = method + ':' + direction
      this.$emit('sort-order-change', sort)
    },

    sortIconClass(method) {
      if (this.sortBy !== method) return 'text-gray-500'
      return 'text-gray-800'
    },

    sortIcon(method) {
      if (this.sortBy !== method) return 'sort'
      if (this.sortDirection === 'asc') return 'caret-down'
      return 'caret-up'
    }
  }
}
</script>
